import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as dat from "dat.gui";
import Stats from "stats.js";

const gui = new dat.GUI();

var stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
document.body.appendChild(stats.dom);

var dynParams = {
  particleSize: 2.3,
  particleAlpha: false,
  scrollSmooth: 4,
  mouseSmooth: 10,
  mouseFactor: 10,
  zoomFactor: window.innerWidth / window.innerHeight < 1 ? 0.5 : 1
};
gui.add(dynParams, "particleSize", 0.1, 20).onChange(function () {
  pointsMaterial.size = dynParams.particleSize;
});
gui.add(dynParams, "zoomFactor", 0.1, 20).onChange(function () {
  camera.zoom = dynParams.zoomFactor;
}).listen();

gui.add(dynParams, "particleAlpha").onChange(function () {
  if (dynParams.particleAlpha) {
    pointsMaterial.transparent = true;
    pointsMaterial.alphaTest = 0.5;
  } else {
    pointsMaterial.transparent = false;
    pointsMaterial.alphaTest = false;
  }
  pointsMaterial.needsUpdate = true;
  // pointsMaterial.size = dynParams.particleSize;
});

gui.add(dynParams, "scrollSmooth", 0, 100);
gui.add(dynParams, "mouseSmooth", 0, 100);
gui.add(dynParams, "mouseFactor", 0, 100);

let camera, scene, renderer, helperCamera, mixer, blenderScene;

let frames = {
  current: 0,
  prev: 0,
  next: 0,
  max: 0,
  frameRate: 30,
  isNew: false,
  direction: undefined,
};
let mixers = [];
let actions = [];
let elapsedTime = 0;
let maxClipDuration = 0;
let animationScrollHeight;
const container = document.querySelector("#container");

let targetScrollPos = -1;
let scrollPos = window.scrollY;

let targetOffsetX = 0;
let targetOffsetY = 0;
let offsetX = 0;
let offsetY = 0;

let camera_placeholder;
let point_placeholder;

let pointsMaterial;

init();
// render();

function init() {
  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

  // Y-Axis up to Z-Axis Up
  THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.appendChild(renderer.domElement);
  scene = new THREE.Scene();

  // optional helper camera
  helperCamera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    0.01,
    40
  );
  helperCamera.position.set(0, -4, 0);
  scene.add(helperCamera);

  let controls = new OrbitControls(helperCamera, renderer.domElement);
  controls.addEventListener("change", render); // use if there is no animation loop
  controls.minDistance = 0.5;
  controls.maxDistance = 20;

  // scene.add(new THREE.AxesHelper(1));

  // animationScrollHeight = container.clientHeight;
  animationScrollHeight = document.body.scrollHeight - window.innerHeight;

  main();
  window.addEventListener("resize", onWindowResize);
  window.addEventListener("scroll", onScroll);
  window.addEventListener("mousemove", onMouseMove);
  window.addEventListener("touchmove", handleTouchMove);
}

function onMouseMove(e) {
  targetOffsetX = (e.clientX / window.innerWidth - 0.5) * 2;
  targetOffsetY = (e.clientY / window.innerHeight - 0.5) * 2;
}

function handleTouchMove(e) {
  const touchStartX = e.changedTouches[0].screenX;
  const touchStartY = e.changedTouches[0].screenY;
  targetOffsetX = (touchStartX / window.innerWidth - 0.5) * 2;
  targetOffsetY = (touchStartY / window.innerHeight - 0.5) * 2;
}

function delayTo(von, nach, delay) {
  if (delay < 0.001) {
    return nach;
  }

  von = von * 100;
  nach = nach * 100;
  const dif = nach - von;
  if (Math.round(Math.abs(dif)) > 0) {
    return (von + dif / delay) / 100;
  } else {
    return nach / 100;
  }
}

function main() {
  // load camera scene from blender file
  const loader = new GLTFLoader();
  loader.load("ffs_pc_v6.gltf", function (gltf) {
    // loader.load("210730_kreditkarte.gltf", function (gltf) {
    blenderScene = gltf.scene;
    blenderScene.position.copy(new THREE.Vector3(0, 0, 0));
    scene.add(blenderScene);

    point_placeholder = blenderScene.children.find(
      (c) => c.name === "pointcloud_placeholder"
    );
    camera_placeholder = blenderScene.children.find(
      (c) => c.name === "camera_placeholder"
    );

    // add camera
    camera = gltf.cameras[0]; // set camera to blender camera settings
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.zoom = dynParams.zoomFactor

    if (camera_placeholder) {
      camera.position.set(
        camera_placeholder.position.x,
        camera_placeholder.position.y,
        camera_placeholder.position.z
      );
      camera.rotation.set(
        camera_placeholder.rotation.x,
        camera_placeholder.rotation.y,
        camera_placeholder.rotation.z,
        camera_placeholder.rotation.order
      );
    }

    scene.add(camera);
    // const helper = new THREE.CameraHelper(camera);
    // scene.add(helper);

    console.log(camera);
    console.log(gltf, blenderScene);

    // animation from gtlf
    mixer = new THREE.AnimationMixer(blenderScene);
    mixers.push(mixer);

    // play all animations at once
    gltf.animations.forEach((clip) => {
      let action = mixer.clipAction(clip).play();
      actions.push(action);
      action.clampWhenFinished = true;
      action.play();

      // get the longest duration of any animation / clip
      if (clip.duration > maxClipDuration) {
        maxClipDuration = clip.duration;
        frames.max = Math.floor(maxClipDuration * frames.frameRate);
        console.log("Max Clip Duration", maxClipDuration);
      }
    });

    const sprite = new THREE.TextureLoader().load("disc.png");
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
    );
    dracoLoader.load("flowers_merged.drc", function (geometry) {
      const material = new THREE.PointsMaterial({
        vertexColors: THREE.VertexColors,
        sizeAttenuation: false,
        map: sprite,
      });
      pointsMaterial = material;
      material.size = dynParams.particleSize; // point size
      const points = new THREE.Points(geometry, material);
      if (point_placeholder) {
        points.geometry.center();
        points.geometry.translate(
          point_placeholder.position.x,
          point_placeholder.position.y,
          point_placeholder.position.z + 1
        );
        points.geometry.rotateX(point_placeholder.rotation.y); // this is because of switched axis
        points.geometry.rotateY(-point_placeholder.rotation.x); // this is because of switched axis
        points.geometry.rotateZ(point_placeholder.rotation.z);
        points.geometry.scale(
          point_placeholder.scale.x * 1, // needed to downscale model
          point_placeholder.scale.y * 1, // needed to downscale model
          point_placeholder.scale.z * 1 // needed to downscale model
        );
      } else {
        points.geometry.center();
        points.geometry.translate(-0.5, 0, 0);
        points.geometry.rotateX(1.75);
        // points.geometry.rotateY(0.8 * Math.PI);
        points.geometry.rotateZ(-0.5 * Math.PI);
      }

      scene.add(points);

      onWindowResize();
      frameTime = Date.now();
      window.requestAnimationFrame(step);
      render();
    });
  });
}

// updating frame helper
function updateFrames(time) {
  var frame = Math.floor(time * frames.frameRate);
  if (frame !== frames.current) {
    frames.prev = frames.current;
    frames.current = frame;
    frames.next = frames.current + 1;
    frames.isNew = true;
    frames.direction = frames.prev < frames.next ? "forward" : "backward";
  } else {
    frames.isNew = false;
  }
}

// updating animation mixer
function update() {
  var y = scrollPos;
  if (y < 1) {
    y = 1;
  }
  for (var mixer of mixers) {
    mixer.time = 0;
    for (var i = 0; i < mixer._actions.length; i++) {
      mixer._actions[i].time = 0;
    }

    // mixer.update(y/1000)

    elapsedTime = (y / animationScrollHeight) * maxClipDuration;

    // as long as the animation is not finished, update the mixer
    if (elapsedTime < maxClipDuration) {
      mixer.update(elapsedTime);
      updateFrames(mixer.time);
      // console.log(elapsedTime);
    }
  }
  camera.position.set(
    camera_placeholder.position.x + offsetX * (dynParams.mouseFactor / 100),
    camera_placeholder.position.y + offsetY * (dynParams.mouseFactor / 100),
    camera_placeholder.position.z
  );
  camera.rotation.set(
    camera_placeholder.rotation.x,
    camera_placeholder.rotation.y,
    camera_placeholder.rotation.z,
    camera_placeholder.rotation.order
  );
  camera.updateProjectionMatrix();
  render();
}

// scroll handler
function onScroll() {
  targetScrollPos = window.scrollY;
  // if (scrollPos <= animationScrollHeight) {
  //   // renderer.domElement.style.transform = "translateY(" + scrollPos + "px)";
  //   // update();
  //   // updateFrames();
  // }
}

let frameTime = Date.now();

function step() {
  stats.begin();

  const now = Date.now();
  const lastFrame = now - frameTime;
  const targetFrameTime = 1000 / 60;

  // calc the steps happend since last frame has been rendered
  // e.g. 1 = 60fps, 2 = 30fps
  // this way the delay can be calculated related to time and not fps
  // so low fps is not making the delay ani slower and instead is skipping frames
  const cSteps = lastFrame / targetFrameTime;

  const updatesToRender =
    targetScrollPos != scrollPos ||
    targetOffsetX != offsetX ||
    targetOffsetY != offsetY;

  // Render only if input values have been updated
  if (updatesToRender) {
    // add delay calc from target to render value
    scrollPos = delayTo(
      scrollPos,
      targetScrollPos,
      dynParams.scrollSmooth / cSteps
    );
    offsetX = delayTo(offsetX, targetOffsetX, dynParams.mouseSmooth / cSteps);
    offsetY = delayTo(offsetY, targetOffsetY, dynParams.mouseSmooth / cSteps);
    update();
    updateFrames();
  }

  frameTime = now;

  stats.end();

  // check for updates on request animation frame to prevent multiple render attempts per frame
  window.requestAnimationFrame(step);
}

// resize handler
function onWindowResize() {
  const aspectRatio = window.innerWidth / window.innerHeight
  camera.aspect = aspectRatio;
  if(aspectRatio < 1 && dynParams.zoomFactor === 1) {
    dynParams.zoomFactor = 0.5
    camera.zoom = dynParams.zoomFactor
  } else if(aspectRatio > 1 && dynParams.zoomFactor === 0.5) {
    dynParams.zoomFactor = 1
    camera.zoom = dynParams.zoomFactor
  }
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
  render();
}

function render() {
  renderer.render(scene, camera);
}
